import React, { useCallback, useEffect, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { constants } from "../../../constants";
import styled from "styled-components";
import { Container, Grid } from "@material-ui/core";
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import { Controller, useForm, useWatch } from "react-hook-form";
import FormInput from "../../../UI/Form/FormInput";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { SettingsSchema } from '../schemas';
import { Form } from '../../../UI/Form/MyForm';
import { colors } from '../../../constants';
import Button from '../../../UI/Button';
import SaveButtons from "../../../UI/Button/SaveButtons";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/react-hooks";
import { settingsQuery, updateSettingsMutation } from "../graphql/mutations";
import FormFinancials from "../components/FormFinancials";
import ShopperSelectorWithData from "../../../package/src/ShopperSelectorWithData";
import { gdprDistortMutation, gdprUpdateTermsMutation } from "../graphql/mutations";
import { gdprQuery, stripeListPaymentMethodsQuery } from "../graphql/queries";
import FormSettingsMerchantInfo from "../components/FormSettingsMerchantInfo";
import Select from "../../../UI/Select";
import { Auth } from "aws-amplify";
import { Country, State, City }  from 'country-state-city';
import FormSettingsMerchantShipping from "../components/FormSettingsMerchantShipping";
import BillingInformation from "../components/BillingInformation";
import FormInputPhoneNumber from "../../../UI/Form/FormInputPhoneNumber";
import { useTranslation } from "react-i18next";

const StyledContainer = styled(Container)`
  max-width: ${props => props.showgdpr ? '1000px' : '700px'};
`

const StyledDescription = styled.p`
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.black};
  margin-bottom: 24px;
  
  span {
    font-weight: 700;
    color: ${colors.blue};
  }
`

const settingList = [
  {
    name: "merchant",
    groupSlugs: null,
  },
  {
    name: "financials",
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  },
  {
    name: "credit-card",
    merchantOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  },
  {
    name: "gdpr",
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  },
]

const getSettingsConditions = (viewer) => {
  let result = settingList;

  if (viewer?.type !== "merchant") {
    result = result.filter(route => !route.merchantOnly);
  }

  const viewerGroupSlugs = viewer?.groups?.nodes?.map(group => group.slug) || [];

  // Filter keeps the route if value returned is true and discard the route if
  // value returned is false
  result = result.map(
    // Some will return true if at least one of the returns is true
    // Note: always allow system-manager to access everything (that is the god of all gods - ZEUS!!!!!)
    route => {
      if (
        !route?.groupSlugs ||
        [...route?.groupSlugs, constants.ACCOUNT_TYPES.systemManager].some(groupSlug =>
          viewerGroupSlugs.find(gs => gs === groupSlug)
        )
      ) {
        return route.name;
      }
    }
  );

  return {
    showFinancials: result.includes('financials'),
    // showCreditCard: result.includes('credit-card'),
    showGDPR: result.includes('gdpr'),
  }
}

const Settings = ({ history, viewer }) => {
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();

  const godmode = viewer?.type === "godmode";

  const [loading, setLoading] = useState(false);
  const [isDistortingGlobally, setIsDistortingGlobally] = useState(false);
  const [isDistortingSpecificShoppers, setIsDistortingSpecificShoppers] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);

  const [financials, setFinancials] = useState([]);
  const [shopperSelection, setShopperSelection] = useState([]);
  const [attributes, setAttributes] = useState({});
  const [countriesList, setCountriesList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [refetchValue, setRefetch] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [phoneError, setPhoneError] = useState(null);

  const refetch = () => {
    setRefetch(prev => !prev);
  }

  const { control, handleSubmit, formState: { errors, isDirty }, setValue, getValues, setError } = useForm({
    resolver: yupResolver(SettingsSchema),
    defaultValues: {
      // company info
      companyName: '',
      email: '',
      phoneNumber: '',
      country: '',
      province: '',
      city: '',
      street: '',
      houseNumber: '',
      postcode: '',
      registrationNumber: '',
      taxNumber: '',

      // gdpr
      gdprTerms: '',
    },
  });

  const watchCountry = useWatch({ control, name: "country" });
  const watchProvince = useWatch({ control, name: "province" });

  useEffect(() => {
    if (watchCountry) {
      const states = State.getStatesOfCountry(watchCountry).map(item => ({
        name: item.name,
        value: item.isoCode,
      }));
      setProvinceList(states);

      const currentProvince = getValues('province');
      if (!currentProvince || !states.find(item => item.value === currentProvince)) {
        setValue('province', states[0]?.value || '');
      }
    }
  }, [watchCountry])

  useEffect(() => {
    if (watchProvince) {
      const cities = City.getCitiesOfState(watchCountry, watchProvince).map(item => ({
        name: item.name,
        value: item.name,
      }));
      setCityList(cities);

      const currentCity = getValues('city');
      if (!currentCity || !cities.find(item => item.value === currentCity)) {
        setValue('city', cities[0]?.value || '');
      }
    }
  }, [watchProvince, watchCountry])

  const {showFinancials, showGDPR} = getSettingsConditions(viewer);

  const shopIds =
    (viewer?.adminUIShops &&
      viewer.adminUIShops.length &&
      viewer.adminUIShops.map(shop => shop._id)) ||
    [];

  const getFinancialsData = () => {
    if (!showFinancials || !godmode) {
      return;
    }
    return apolloClient.query({
      query: settingsQuery,
      fetchPolicy: "network-only",
    })
  }

  const getStripePaymentMethods = () => {
    setLoadingPayment(true);

    apolloClient.query({
      query: stripeListPaymentMethodsQuery,
      variables: {
        input: {
          companyId: viewer.companyId,
        },
      },
      fetchPolicy: "network-only",
    })
      .then((res) => {
        const paymentMethods = res.data?.stripeListPaymentMethods || [];
        setPaymentMethods(paymentMethods);
      })
      .catch((err) => {
        console.log(err.message);
        setPaymentMethods([]);
      })
      .finally(() => {
        setLoadingPayment(false);
      });
  }

  useEffect(() => {
    if (godmode) {
      return;
    }

    getStripePaymentMethods();
  }, [godmode]);

  const updateFinancials = () => {
    if (!showFinancials || !godmode) {
      return;
    }

    const data = financials.map(setting => ({
      id: setting._id,
      data: {
        name: setting.name,
        value: setting.value,
        shopId: setting.shopId,
      },
    }));

    return apolloClient.mutate({
      mutation: updateSettingsMutation,
      variables: {
        input: {
          settings: data,
        },
      },
    })
  }

  const getGDPR = () => {
    if (!showGDPR || !godmode) {
      return;
    }
    return apolloClient.query({
      query: gdprQuery,
      fetchPolicy: "network-only",
    })
  }

  const updateGDPR = (terms) => {
    if (!showGDPR || !godmode) {
      return;
    }
    return apolloClient.mutate({
      mutation: gdprUpdateTermsMutation,
      variables: {
        input: {
          terms,
        },
      },
    })
  }

  const getCognitoUserAttributes = async () => {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.userAttributes(user);
  }

  useEffect(() => {
    setLoading(true);

    const countries = Country.getAllCountries().map(item => ({
      name: item.name,
      value: item.isoCode,
    }));
    setCountriesList(countries);

    Promise.allSettled([
      getFinancialsData(),
      getGDPR(),
      getCognitoUserAttributes(),
    ])
      .then(res => {
        const financials = res[0]?.value?.data?.settings;
        if (financials) {
          setFinancials(financials);
        }

        const terms = res[1]?.value?.data?.gdpr?.terms;
        if (terms) {
          setValue('gdprTerms', terms);
        }

        const attributes = res[2]?.value;

        if (attributes) {
          const attrObj = {};

          attributes.forEach(item => {
            attrObj[item.Name] = item.Value;
          })

          setAttributes(attrObj);

          setValue('companyName', attrObj.profile || '');
          setValue('email', attrObj.email || '');
          setValue('phoneNumber', attrObj.phone_number || '');
          setValue('country', attrObj.locale || '');
          setValue('province', attrObj['custom:province'] || '');
          setValue('city', attrObj['custom:city'] || '');
          setValue('street', attrObj['custom:street'] || '');
          setValue('houseNumber', attrObj['custom:houseNumber'] || '');
          setValue('postcode', attrObj['custom:postcode'] || '');
          setValue('registrationNumber', attrObj['custom:registrationNumber'] || '');
          setValue('taxNumber', attrObj['custom:taxNumber'] || '');
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.message.replace("GraphQL error:", ""), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setLoading(false))
  }, [viewer, refetchValue])

  const updateAdminFields = async (data) => {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.updateUserAttributes(user, {
      profile: data.companyName,
      email: data.email,
      phone_number: data.phoneNumber,
      locale: data.country,
      'custom:province': data.province,
      'custom:city': data.city,
      'custom:street': data.street,
      'custom:houseNumber': data.houseNumber,
      'custom:postcode': data.postcode,
      'custom:registrationNumber': data.registrationNumber,
      'custom:taxNumber': data.taxNumber,
    });
  }

  const onSubmit = useCallback((formData) => {
    setLoading(true);

    Promise.allSettled([
      updateAdminFields(formData),
      updateFinancials(),
      updateGDPR(formData.gdprTerms),
    ])
      .then(() => {
        enqueueSnackbar(t('snackbar.update_success'), { variant: "success" });
      })
      .catch((e) => {
        const message = e.message;

        if (typeof message === "string" && message.indexOf("Invalid phone number format") > -1) {
          setError("phoneNumber", { message: t('errors.invalid_phone_format') });
          return;
        }

        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(message);
      })
      .finally(() => setLoading(false));
  }, [])

  const distortShoppers = useCallback((accountIds) => {
    const variables = {};
    if (accountIds.length) {
      variables.accountIds = accountIds;
    }

    return apolloClient.mutate({
      mutation: gdprDistortMutation,
      variables,
    })
      .then(() => {
        const message = accountIds.length > 1 ? t('settings.distort_many_success') : t('settings.distort_success')
        enqueueSnackbar(message, { variant: "success" });
      })
      .catch((e) => {
        enqueueSnackbar(e.message.replace("GraphQL error:", ""), {variant: 'error'});
        console.log(e.message);
      })
  }, [])

  const handleDistortSpecificShopper = () => {
    const ids = shopperSelection.map(shopper => shopper.value);
    setIsDistortingSpecificShoppers(true);
    distortShoppers(ids)
      .finally(() => setIsDistortingSpecificShoppers(false))
  }

  const handleDistortGlobal = () => {
    setIsDistortingGlobally(true);
    distortShoppers([])
      .finally(() => setIsDistortingGlobally(false))
  }

  const handleChangeFinancialSettings = (field, value) => {
    setFinancials(prev => {
      const newData = [...prev];
      const index = newData.findIndex(item => item.name === field);
      newData[index].value = value;
      return newData;
    });
  }

  const declineAction = () => {
    history.goBack();
  }

  return (
    godmode ?

      // admin settings

      <StyledContainer showgdpr={String(showGDPR)}>
        <Toolbar title={t('settings.settings')}/>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          width='100%'
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={showGDPR ? 7 : 12}>
              <FormCard>
                <FormHeader optionalText='Wondersouq'>{t('settings.company_info')}</FormHeader>
                <InputLabel disabled={loading} error={errors.companyName}>{t('settings.legal_company_name')}</InputLabel>
                <Controller
                  name="companyName"
                  control={control}
                  render={({ field }) => <FormInput
                    placeholder={t('settings.write_company_name')}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                    error={errors.companyName}
                  />}
                />
                <InputLabel disabled={loading} error={errors.email}>{t('settings.email')}</InputLabel>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => <FormInput
                    placeholder={t('settings.write_email')}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                    error={errors.email}
                  />}
                />
                <InputLabel disabled={loading}>{t('settings.phone_number')}</InputLabel>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => <FormInputPhoneNumber
                    placeholder={t('settings.write_phone_number')}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                    error={errors.phoneNumber}
                    setPhoneError={setPhoneError}
                    phoneError={phoneError}
                  />}
                />
                <InputLabel disabled={loading}>{t('settings.country')}</InputLabel>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => <Select
                    placeholder={t('settings.choose_country')}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                    options={countriesList}
                    error={errors.country}
                  />}
                />
                <InputLabel disabled={loading}>{t('settings.province')}</InputLabel>
                <Controller
                  name="province"
                  control={control}
                  render={({ field }) => <Select
                    placeholder={t('settings.choose_province')}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                    options={provinceList}
                    error={errors.province}
                  />}
                />
                <InputLabel disabled={loading}>{t('settings.city')}</InputLabel>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => <Select
                    placeholder={t('settings.choose_city')}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                    options={cityList}
                    error={errors.city}
                  />}
                />

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <InputLabel disabled={loading}>{t('settings.street')}</InputLabel>
                    <Controller
                      name="street"
                      control={control}
                      render={({ field }) => <FormInput
                        placeholder={t('settings.write_street')}
                        value={field.value}
                        onChange={field.onChange}
                        disabled={loading}
                      />}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel disabled={loading}>{t('settings.house_number')}</InputLabel>
                    <Controller
                      name="houseNumber"
                      control={control}
                      render={({ field }) => <FormInput
                        value={field.value}
                        onChange={field.onChange}
                        disabled={loading}
                      />}
                    />
                  </Grid>
                </Grid>

                <InputLabel disabled={loading}>{t('settings.postcode')}</InputLabel>
                <Controller
                  name="postcode"
                  control={control}
                  render={({ field }) => <FormInput
                    placeholder='Write postcode'
                    value={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                  />}
                />
                <InputLabel disabled={loading}>{t('settings.comm_reg_number')}</InputLabel>
                <Controller
                  name="registrationNumber"
                  control={control}
                  render={({ field }) => <FormInput
                    placeholder={t('settings.write_comm_reg_number')}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                  />}
                />
                <InputLabel disabled={loading}>{t('settings.tax_number')}</InputLabel>
                <Controller
                  name="taxNumber"
                  control={control}
                  render={({ field }) => <FormInput
                    placeholder={t('settings.write_tax_number')}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                  />}
                />
              </FormCard>
              {
                showFinancials &&
                  <FormCard>
                    <FormHeader>{t('settings.financials')}</FormHeader>
                    {
                      financials.map(item => <FormFinancials
                        key={item._id}
                        setting={item}
                        handleChange={handleChangeFinancialSettings}
                      />)
                    }
                  </FormCard>
              }
              {
                showGDPR &&
                  <FormCard>
                    <FormHeader>{t('settings.gdpr')}</FormHeader>
                    <InputLabel disabled={loading}>{t('settings.terms_conditions')}</InputLabel>
                    <Controller
                      name="gdprTerms"
                      control={control}
                      render={({ field }) => <FormInput
                        multiline
                        rows={15}
                        placeholder={t('settings.write_terms_conditions')}
                        value={field.value}
                        onChange={field.onChange}
                        disabled={loading}
                      />}
                    />
                  </FormCard>
              }
            </Grid>

            {
              showGDPR &&
                <Grid item xs={12} lg={5}>
                  <FormCard>
                    <FormHeader>{t('settings.gdpr_shopper_distortion')}</FormHeader>
                    <InputLabel disabled={loading || isDistortingSpecificShoppers}>{t('settings.distort_data_shoppers')}</InputLabel>
                    <ShopperSelectorWithData
                      shouldShowEmail
                      shopIds={shopIds}
                      value={shopperSelection}
                      onSelection={setShopperSelection}
                      disabled={loading || isDistortingSpecificShoppers}
                      placeholder={t('settings.choose_shoppers')}
                    />
                    <Button
                      width='150px'
                      onClick={handleDistortSpecificShopper}
                      disabled={loading || isDistortingSpecificShoppers || !shopperSelection.length}
                    >
                      {t('settings.distort')}
                    </Button>
                  </FormCard>

                  <FormCard>
                    <FormHeader>{t('settings.gdpr_global_distortion')}</FormHeader>
                    <StyledDescription>{t('settings.click_to_distort')} <span>{t('settings.all_inactive_users')}</span>. {t('settings.user_deemed_inactive')} <span>{t('settings.30_days')}</span> {t('settings.straight')}.</StyledDescription>
                    <Button
                      width='150px'
                      onClick={handleDistortGlobal}
                      disabled={loading || isDistortingGlobally}
                    >
                      {t('settings.distort')}
                    </Button>
                  </FormCard>
                </Grid>
            }
          </Grid>

          <SaveButtons margin='24px 0' justifyContent='flex-end'>
            <Button
              width='180px'
              type='submit'
              disabled={!isDirty || loading || Boolean(phoneError)}
            >
              {t('ui.save_changes')}
            </Button>
            <Button
              mytype='third'
              width='180px'
              handleClick={declineAction}
              disabled={loading}
            >
              {t('ui.cancel')}
            </Button>
          </SaveButtons>
        </Form>
      </StyledContainer> :

      // merchant settings

      <StyledContainer showgdpr='true'>
        <Toolbar title={t('settings.company_settings')}/>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <FormSettingsMerchantInfo
              companyId={viewer.companyId}
              loading={loading}
              attributes={attributes}
              setLoading={setLoading}
              countriesList={countriesList}
              refetch={refetch}
            />
            <FormSettingsMerchantShipping
              loading={loading}
              setLoading={setLoading}
              countriesList={countriesList}
              attributes={attributes}
              refetch={refetch}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <BillingInformation
              paymentMethods={paymentMethods}
              refetchPayments={getStripePaymentMethods}
              loading={loadingPayment}
              companyId={viewer?.companyId || ''}
            />
          </Grid>
        </Grid>
      </StyledContainer>
  );
}

export default Settings;
