import React from "react";
import FormInput from "../../../UI/Form/FormInput";

const DateSelector = (props) => {
  return (
    <FormInput
      type='date'
      {...props}
    />
  );
};

export default DateSelector;
