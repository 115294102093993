import React, { useCallback, useContext, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import Dropdown from "../../../UI/Dropdown";
import Search from "../../../UI/Search";
import StyledButton from "../../../UI/Button";
import DataTable from "../../../UI/DataTable/DataTable";
import { colors, constants, standardPageStyles } from "../../../constants";
import Dialog from "../../../UI/Dialog/Dialog";
import { useSnackbar } from "notistack";
import { tagListingQuery } from "../lib/queries";
import { useApolloClient } from "@apollo/react-hooks";
import svgEdit from "../../../icons/table-edit.svg";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import svgApprove from "../../../icons/table-approve.svg";
import svgDelete from "../../../icons/delete.svg";
import { removeTagMutation, updateTagMutation } from "../lib/mutations";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import DialogCreateEditTag from './TagFormPageWithData';
import { LanguageContext } from "../../../package/src/context/LanguageContext";
import { useTagsColumns } from "../hooks/useTagsColumns";
import { useTranslation } from "react-i18next";
import useModifiers from "../hooks/useModifiers";

const useStyles = makeStyles(theme => ({
  ...standardPageStyles,
  redText: {
    color: colors.red,
    fontWeight: 700,
  },
}));

const TagTable = ({ selectedShopIds: shopIds, viewer, history }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const { isRtl } = useContext(LanguageContext);
  const rtl = isRtl ? 'Rtl' : '';

  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowId, setRowId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [exporting, setExporting] = useState(false);

  const columns = useTagsColumns();
  const { modifyData } = useModifiers();

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
      // Wait for shop id to be available before fetching products.
      if (shopIds.length === 0) {
        return;
      }

      setIsLoading(true);
      apolloClient.query({
        query: tagListingQuery,
        variables: {
          shopIds,
          filter: globalFilter,
          first: pageSize,
          limit: (pageIndex + 1) * pageSize,
          offset: pageIndex * pageSize,
          sortOrder,
          sortBy,
        },
        fetchPolicy: "network-only",
      })
        .then(res => {
          // Update the state with the fetched data as an array of objects and the calculated page count
          const total = res.data.tags.totalCount;
          setTableData(res.data.tags.nodes);
          setPageCount(Math.ceil(total / pageSize));
          setTotalCount(total);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setIsLoading(false))
    }, [shopIds, t]);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: tagListingQuery,
      variables: {
        shopIds:
          (viewer?.adminUIShops &&
            viewer.adminUIShops.length &&
            viewer.adminUIShops.map(shop => shop._id)) ||
          [],
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyData(res.data.tags.nodes, 'export');
        const fields = columns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [viewer, modifyData, t]);

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const getTagParams = useCallback((rowId) => {
    const found = tableData.find(item => item._id === rowId);
    return {
      shopId: found.shop._id,
      slug: found.slug,
      name: found.displayTitle,
    }
  }, [tableData]);

  const mutateTagStatus = useCallback((name, slug, value, tagId, shopId) => {
    return apolloClient.mutate({
      mutation: updateTagMutation,
      variables: {
        input: {
          id: tagId,
          shopId: shopId,
          isVisible: value,
          name: slug,
          displayTitle: name,
        },
      },
    });
  }, [])

  const deleteTag = useCallback((tagId, shopId) => {
    return apolloClient.mutate({
      mutation: removeTagMutation,
      variables: {
        input: {
          id: tagId,
          shopId: shopId,
        },
      },
    });
  }, [])

  const getShopId = (rowId) => {
    return tableData.find(item => item._id === rowId).shop._id;
  }

  const tagOptions = useMemo(() => [
    {
      value: constants.OPTIONS.editTag,
      label: t('tags.edit_tag'),
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        const shopId = getShopId(rowId);
        setSelectedRows(prev => ([
          shopId,
          ...prev,
        ]));
        setDialogName('editTag');
        setDialogOpen(true);
      },
    },
    {
      value: constants.OPTIONS.makeVisible,
      label: t('tags.make_visible'),
      icon: svgApprove,
      handleOptionClick: async (rowId) => {
        const {shopId, slug, name} = getTagParams(rowId);
        const {data, error} = await mutateTagStatus(name, slug, true, rowId, shopId);
        if (error) {
          enqueueSnackbar(t('snackbar.common_error'), { variant: 'error' });
        } else if (data) {
          refetch();
          enqueueSnackbar(t('snackbar.action_rows_success'));
        }
      },
    },
    {
      value: constants.OPTIONS.makeHidden,
      label: t('tags.make_hidden'),
      icon: svgApprove,
      handleOptionClick: async (rowId) => {
        const {shopId, slug, name} = getTagParams(rowId);
        const {data, error} = await mutateTagStatus(name, slug, false, rowId, shopId);
        if (error) {
          enqueueSnackbar(t('snackbar.common_error'), { variant: 'error' });
        } else if (data) {
          refetch();
          enqueueSnackbar(t('snackbar.action_rows_success'));
        }
      },
    },
    {
      value: constants.OPTIONS.delete,
      label: t('ui.delete'),
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('deleteSingle');
        setDialogOpen(true);
      },
    },
  ], [getTagParams, t])

  const itemsListActions = useMemo(() => [
    {
      content: t('tags.make_visible'),
      isDisabled: selectedRows.length === 0,
      action: async () => {
        const successes = [];

        try {
          for (const rowId of selectedRows) {
            const {shopId, slug, name} = getTagParams(rowId);
            const { data } = await mutateTagStatus(name, slug, true, rowId, shopId);
            if (data) successes.push(data);
          }
        } catch(e) {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        }

        if (successes.length) {
          refetch();
          enqueueSnackbar(t('snackbar.action_rows_success'), {variant: 'success'});
        }
      },
    },
    {
      content: t('tags.make_hidden'),
      isDisabled: selectedRows.length === 0,
      action: async () => {
        const successes = [];

        try {
          for (const rowId of selectedRows) {
            const {shopId, slug, name} = getTagParams(rowId);
            const { data } = await mutateTagStatus(name, slug, false, rowId, shopId);
            if (data) successes.push(data);
          }
        } catch(e) {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        }

        if (successes.length) {
          refetch();
          enqueueSnackbar(t('snackbar.action_rows_success'), {variant: 'success'});
        }
      },
    },
    {
      content: <span className={classes.redText}>{t('ui.delete')}</span>,
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('delete');
        setDialogOpen(true);
      },
    },
  ], [selectedRows, getTagParams, t])

  const renderNoButton = useMemo(() =>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={() => setDialogOpen(false)}
      >
        {t('ui.no')}
      </StyledButton>
    , [t])

  const DialogDeleteSingle = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('tags.delete_tag_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              const {shopId} = getTagParams(rowId);
              deleteTag(rowId, shopId)
                .then(res => {
                  if (res) {
                    refetch();
                    enqueueSnackbar(t('snackbar.action_rows_success'), {variant: 'success'});
                  }
                })
                .catch((e) => {
                  enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [rowId, t])

  const DialogDelete = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('tags.delete_tags_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async () => {
              setDialogOpen(false);
              const successes = [];

              try {
                for (const rowId of selectedRows) {
                  const {shopId} = getTagParams(rowId);
                  const {data} = await deleteTag(rowId, shopId);
                  if (data) successes.push(data);
                }
              } catch(e) {
                enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                console.log(e.message);
              }

              if (successes.length) {
                refetch();
                enqueueSnackbar(t('snackbar.action_rows_success'), {variant: 'success'});
              }
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    );
  }, [getTagParams, selectedRows, t]);

  const dataProps = useMemo(() => {
    return modifyData(tableData, '', tagOptions);
  }, [tableData, modifyData])

  const handleCreateTag = () => {
    if (shopIds.length !== 1) {
      enqueueSnackbar(t('tags.please_select_one_shop'), { variant: "error" });
      return;
    }
    setRowId('');
    setDialogName('createTag');
    setDialogOpen(true);
  }

  const renderExportAndCreate = (myClass) =>
    <Grid item xs={12} lg={5} className={classes[myClass]}>
      <StyledButton
        width='140px'
        mytype='secondary'
        handleClick={exportData}
        disabled={exporting || !tableData.length}
      >
        {t('ui.export')}
      </StyledButton>
      <StyledButton
        minwidth='160px'
        width='220px'
        handleClick={handleCreateTag}
      >
        {t('tags.create_tag')}
      </StyledButton>
    </Grid>

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'deleteSingle': return DialogDeleteSingle;
      case 'delete': return DialogDelete;
      case 'editTag':
      case 'createTag': return <DialogCreateEditTag
          shopId={dialogName === 'createTag' ? shopIds[0] : getTagParams(rowId)?.shopId}
          viewerId={viewer._id}
          tagId={rowId}
          setTagId={(id) => setRowId(id)}
          closeDialog={() => setDialogOpen(false)}
          page={dialogName}
          refetch={refetch}
        />;
      default: return null;
    }
  }

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Toolbar title={t('tags.tags')} />

        {
          shopIds.length !== 0 && <>
            <Hidden mdDown>
              <Grid container className={classes.grid}>
                <Grid item lg={7} className={classes.gridEmpty}/>
                {renderExportAndCreate(`secondColumn${rtl}`)}
              </Grid>
            </Hidden>

            <Hidden lgUp>
              {renderExportAndCreate('secondColumnXS')}
            </Hidden>

            <div className={classes.secondRow}>
              <Dropdown
                title={t('ui.actions')}
                itemsList={itemsListActions}
              />
              <Search
                handleChange={handleSearch}
                onSearchClick={refetch}
                placeholder={t('tags.search_tag_by_name')}
                useDebounce
              />
            </div>
          </>
        }

        <Grid item sm={12}>
          {shopIds.length === 0 ? (
              <span className={classes.selectSomeShops}>{t('tags.please_select_some_shops')}</span>
            ) :
            <DataTable
              columns={columns}
              isLoading={isLoading}
              data={dataProps}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              handleFetchData={onFetchData}
              pageCount={pageCount}
              totalCount={totalCount}
              searchValue={searchValue}
              reloadTable={reloadTable}
              defaultSortField='createdAt'
              defaultSortOrder='desc'

              // styles for 2 rows upper panel + checkboxes
              maxHeight='calc(100vh - 390px)'
              maxHeightLaptop='calc(100vh - 460px)'
              maxHeightMobile='calc(100vh - 440px)'
            />
          }
        </Grid>

      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>

  );
}

export default TagTable;
