import React, { useContext } from "react";
import {FormControlLabel} from '@material-ui/core';
import styled from 'styled-components';
import {colors} from '../../constants';
import { LanguageContext } from "../../package/src/context/LanguageContext";

const StyledRadio = styled(FormControlLabel)`
  width: 100%;
  margin-right: ${props => props.isrtl === 'true' ? '0' : '16px'};
  margin-left: ${props => props.isrtl === 'true' ? '5px' : '-11px'};
  
  .MuiTypography-root {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: ${colors.black};
  }
  
  .MuiButtonBase-root {
    padding: ${props => props.isrtl === 'true' ? '4px 8px 4px 16px' : '4px 16px 4px 8px'};
  }
`

const CustomRadio = ({children, ...other}) => {
  const { isRtl } = useContext(LanguageContext);

  return (
    <StyledRadio
      isrtl={String(isRtl)}
      {...other}
    >
      {children}
    </StyledRadio>
  );
}

export default CustomRadio;
