import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormHeader from "../../../UI/Form/FormHeader";
import { Form } from "../../../UI/Form/MyForm";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {BrandSchema} from '../schemas';
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import StyledButton from "../../../UI/Button/Button";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import { useTranslation } from "react-i18next";

const FormBrands = ({page, name, brandId, declineAction, confirmAction}) => {
  const { t } = useTranslation();

  const { control, handleSubmit, formState: { errors, isDirty }, setValue } = useForm({
    resolver: yupResolver(BrandSchema),
    defaultValues: {
      name: t('brand.new_brand'),
    },
  });

  useEffect(() => {
    if (page === 'edit' && name) {
      setValue('name', name);
    }
  }, [])

  const onSubmit = (newData) => {
    confirmAction(page, brandId, newData.name);
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeader>{page === 'create' ? t('brand.create_brand') : t('brand.edit_brand')}</FormHeader>
      <InputLabel error={errors.name}>{t('brand.brand_name')}</InputLabel>
      <Controller
        name="name"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('brand.write_brand_name')}
          value={field.value}
          onChange={field.onChange}
          error={errors.name}
        />}
      />

      <DialogButtons marginTop='16px'>
        <StyledButton
          width='180px'
          type="submit"
          disabled={!isDirty}
        >
          {page === 'create' ? t('ui.create') : t('ui.save')}
        </StyledButton>
        <StyledButton
          width='180px'
          mytype='secondary'
          handleClick={declineAction}
        >
          {t('ui.cancel')}
        </StyledButton>
      </DialogButtons>
    </Form>
  );
}

FormBrands.propTypes = {
  page: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  brandId: PropTypes.string.isRequired,
  name: PropTypes.string,
  declineAction: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
}

export default FormBrands;
