import * as Yup from "yup";

const numberValidation = Yup.number()
    .transform((value, original) => {
      return (original === '' ? undefined : value)
    })
    .required('errors.field_required');

export const PackagesSchema = Yup.object().shape({
  title: Yup.string()
    .required('errors.field_required'),
  location: Yup.string()
    .required('errors.field_required'),
  currency: Yup.string()
    .required('errors.field_required'),
  rate: numberValidation,
  period: numberValidation,
  commission: numberValidation,
})
