import React, { useCallback, useEffect, useState } from "react";
import { Tab } from "@material-ui/core";
import Tabs from '../../../UI/Tabs';
import Toolbar from "../../../package/src/Toolbar";
import ShopEditForm from "../components/ShopEditForm";
import Scene from "../../configurator/components/Scene";
import Button from "../../../UI/Button";
import TwoColumnsPage from "../../../package/src/TwoColumnsPage";
import SubscriptionTab from "../components/SubscriptionTab";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { getCompanyWithSubscriptions, getShopCompanyId } from "../graphql/queries";
import PageStatusMessage from "../../../UI/other/PageStatusMessage";
import MapTab from "../components/MapTab";
import { addSubscription, switchSubscription } from "../graphql/mutations";
import { useTranslation } from "react-i18next";

/*
  ГЛОССАРИЙ
  Пакет подписки (далее "пакет") - тариф, который покупает юзер: based, advanced и т.п. См. админское меню Subscription Packages
  Подписка - купленный (или покупаемый) компанией пакет для управления одним магазином
  Актуальная подписка - подписка, которая годится для создания магазина (статус open)

  СТАТУСЫ ПОДПИСОК
  open - компания оформила и оплатила подписку. По этой подписке можно создать магазин
  taken - компания оформила и оплатила подписку и создала по ней магазин. Создать магазин нельзя
  pending - компания оформила подписку, но не оплатила. Ждём оплаты, создать магазин нельзя. После оплаты становится open
  нет подписок (приходит пустой массив) - показать экран со всеми пакетами

  ПРАВИЛА
  * Новая подписка сразу берет статус open, если итоговая стоимость пакета = 0
  * У одной компании единовременно может быть только одна "не taken" подписка.
  * 1 магазин = 1 подписка.
  * На один пакет может быть несколько подписок.

  СОЗДАНИЕ НОВОГО МАГАЗИНА
  Есть pending: показать экран "ждем оплату"
  Есть open, нет pending: можно создать магазин, нельзя создать подписку (магазин записывать в актуальную подписку)
  Нет open, нет pending: показать экран со всеми пакетами

  ПОКАЗ ВКЛАДОК
  Subscription: всегда
  Information: скрывать в режиме создания, если нет актуальной подписки
  Map, Configuration: скрывать в режиме создания
*/

const ShopEditPage = ({match, history, viewer}) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const godmode = viewer?.type === "godmode";

  const shopId = match.params.shopId;
  // если нет shopId, значит мы в режиме создания

  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(null);

  const [companyId, setCompanyId] = useState(null);
  // если мы зашли под админом в режиме редактирования магазина, то сначала запрашивается id компании этого магазина
  // в противном случае используется id компании текущего юзера

  const [packageId, setPackageId] = useState(null);
  // id пакета юзера, приходит с бэка

  const [selectedPackageId, setSelectedPackageId] = useState(null);
  // отвечает за выбранный id пакета и показ его в sub. Если null, то показать список всех пакетов

  const [subscriptionId, setSubscriptionId] = useState(null);
  // id актуальной подписки из subscriptionHistoryRecords юзера, которая нужна для добавления с помощью addSubscription
  // не путать с packageId !

  const [paymentStatus, setPaymentStatus] = useState(null);
  /*
  стейт используется в компоненте Payment - какую вкладку показывать
  null - нет подписок, показать данные для оплаты
  pending - ожидание оплаты пакета, показать ожидание оплаты
  open - показать "успешно прошел платеж, создать магазин"
  taken - магазин занят, показать данные для оплаты
   */

  const [needRefetch, setNeedRefetch] = useState(false);
  /*
  Отложенный рефетч
  Становится true после добавлении подписки методом addSubscription
  Рефетч будет произведен при нажатии на вкладку Information
  После рефетча стейт сразу становится false
   */

  const [processing, setProcessing] = useState(false);
  // loading, процесс оплаты

  const [showOnlyPackageId, setShowOnlyPackageId] = useState(null);
  // Если есть open-подписка, и мы зашли редактировать магазин, то нужно показать только указанный пакет
  // Если сюда назначить невалидный id, например '111', то пропадут все карточки, кроме active plan

  const [paymentError, setPaymentError] = useState(false);
  // Если у юзера не прошёл платеж, то на вкладке Payment показывать текст "ошибка, обратитесь к админу"

  const [paymentPending, setPaymentPending] = useState(false);
  // У юзера по какой-то причине pending статус. Показывать надпись "обратитесь к админу"

  const [openSubscription, setOpenSubscription] = useState(null);
  // Используется для:
  // 1. Проверки open подписки при создании подписки
  // 2. Показывать ли кнопки Create shop / change plan сразу после изменения или покупки подписки.

  const [ getCompanySubscriptions, { data: dataRecord, refetch } ] = useLazyQuery(getCompanyWithSubscriptions, {
    fetchPolicy: "network-only",
    variables: {
      companyId: companyId || viewer.companyId,
    },
    onError: (e) => {
      enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
      console.log(e.message);
    },
  });

  const getCompanyId = () => {
    if (godmode && shopId) {
      return apolloClient.query({
        query: getShopCompanyId,
        variables: {
          id: shopId,
        }
      });
    }

    return Promise.resolve();
  }

  useEffect(() => {
    getCompanyId()
      .then(res => {
        if (res) {
          setCompanyId(res.data.shop.company._id);
        }

        return getCompanySubscriptions();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
  }, [])

  useEffect(() => {
    setLoading(false);

    // open подписка пока не определена, сброс
    setOpenSubscription(null);

    if (!dataRecord) {
      return;
    }

    const records = dataRecord.company.subscriptions;
    //console.log('records', records);

    // no package, no subscription

    if (records.length === 0) {
      setCurrentTab('sub');
      setPaymentStatus(null);
      return;
    }

    // pending

    const dataPending = records.find(item => item.status === 'pending');
    //console.log('dataPending', dataPending);

    if (dataPending && !shopId) {
      // we got pending payment, but page is creating shop
      setPaymentStatus('pending');
      setPaymentPending(true);
      setSelectedPackageId(dataPending.subscriptionPackageId); // select this plan to show
      setPackageId(dataPending.subscriptionPackageId); // active plan
      setCurrentTab('sub');
      return;
    }

    // open

    // сброс ошибок
    setPaymentError(false);
    setPaymentPending(false);

    const dataOpen = records.find(item => item.status === 'open');
    setOpenSubscription(dataOpen);
    //console.log('dataOpen', dataOpen);

    if (dataOpen && !shopId) {
      // we got open sub, but page is creating shop
      setPaymentStatus('open');
      setSubscriptionId(dataOpen._id); // will be used in 'addSubscription' mutation
      setPackageId(dataOpen.subscriptionPackageId); // active plan
      setCurrentTab('info');
      return;
    }

    if (dataOpen && shopId) {
      // we got open sub, but page is editing shop
      setShowOnlyPackageId(dataOpen.subscriptionPackageId);
    } else {
      setShowOnlyPackageId(null);
    }

    // taken, page is creating shop

    if (!shopId) {
      setPaymentStatus('taken');
      setCurrentTab(shopId ? 'info' : 'sub');
      return;
    }

    // taken, page is editing shop
    // здесь может быть ещё и pending статус!

    const dataTaken = records.find(item =>
      shopId === item.shopId &&
      item.status === 'taken'
    );
    //console.log('dataTaken', dataTaken);

    if (dataTaken) {
      setPackageId(dataTaken.subscriptionPackageId); // active plan
    }

    if (dataPending) {
      // если у юзера есть pending, то нельзя изменить план
      setShowOnlyPackageId('1');
    }

    setPaymentStatus(dataPending ? 'pending' : 'taken');
    setCurrentTab(shopId ? 'info' : 'sub');
  }, [dataRecord])

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const mutationAddSubscription = useCallback((paymentMethodId) => {
    if (openSubscription) {
      // если уже есть подписка в статусе open, то не нужно создавать новую
      setSubscriptionId(openSubscription._id);
      setPackageId(openSubscription.subscriptionPackageId);
      setNeedRefetch(true);
      return Promise.resolve(openSubscription._id);
    }

    return apolloClient.mutate({
      mutation: addSubscription,
      variables: {
        id: selectedPackageId,
        paymentMethodId,
      }
    })
      .then(res => {
        const subscription = res.data.addSubscription.subscription;
        if (subscription.status === 'pending') {
          throw new Error('Pending subscription');
        }
        setSubscriptionId(subscription._id);
        setPackageId(subscription.subscriptionPackageId);
        setOpenSubscription(subscription);
        setNeedRefetch(true);
        return subscription._id;
      });
  }, [selectedPackageId, openSubscription, t]);

  const mutationSwitchSubscription = useCallback((id) => {
    return apolloClient.mutate({
      mutation: switchSubscription,
      variables: {
        input: {
          shopId,
          subscriptionId: id,
        },
      }
    })
  }, [shopId]);

  const handleProceedPayment = async ({ hasActive, paymentMethodId }) => {
    let error;
    setProcessing(true);

    // провести оплату
    if (hasActive && shopId) {
      // Нажата кнопка Change plan, смена пакета подписки
      // работает только если мы не находимся в режиме создания магазина
      await mutationAddSubscription(paymentMethodId)
        .then((res) => {
          return mutationSwitchSubscription(res);
        })
        .catch((e) => {
          error = e;
        });
    } else {
      // Нажата кнопка Get started, добавление подписки
      await mutationAddSubscription(paymentMethodId)
        .catch((e) => {
          error = e;
        });
    }

    if (!error) {
      if (hasActive && shopId) {
        // выбран change plan, показать список планов
        setPaymentStatus('taken');
        setSelectedPackageId(null);
      } else {
        // выбран Get started, показать панель с кнопкой Create shop
        setPaymentStatus('open');
      }
    } else {
      if (error.message === 'Pending subscription') {
        setPaymentStatus('pending');
        setPaymentPending(true);
      } else {
        setPaymentError(true);
      }
    }

    setProcessing(false);
  }

  const handleChangeOrSelectPackage = (hasActive, id) => {
    setSelectedPackageId(id);
    if (hasActive) {
      // user want to change plan
      setPaymentStatus(null);
    }
  }

  const renderTab = () => {
    switch (currentTab) {
      case 'sub': return <SubscriptionTab
        setCurrentTab={setCurrentTab}
        viewer={viewer}
        selectedPackageId={selectedPackageId}
        setSelectedPackageId={handleChangeOrSelectPackage}
        packageId={packageId}
        paymentStatus={paymentStatus}
        handleProceedPayment={handleProceedPayment}
        openId={openSubscription?._id}
        shopId={shopId}
        processing={processing}
        showOnlyPackageId={showOnlyPackageId}
        paymentError={paymentError}
        paymentPending={paymentPending}
      />
      case 'info': {
        if (needRefetch) {
          setNeedRefetch(false);
          setSelectedPackageId(null);
          refetch();
          return null;
        }

        return <ShopEditForm
          shopId={shopId}
          subscriptionId={subscriptionId}
          viewer={viewer}
          packageId={packageId}
        />;
      }
      case 'map': return <MapTab
        shopId={shopId}
        packageId={packageId}
        isSubscriptionMode={false}
        setCurrentTab={setCurrentTab}
      />
      case 'conf': return <Scene
        shopId={shopId}
        packageId={packageId}
      />
      default: return null;
    }
  }

  const handleCancel = () => {
    history.push('/shops');
  }

  return (
    <>
      <Toolbar title={shopId ? t('shops.edit_shop') : t('shops.create_shop')} />
      {
        loading || !currentTab ?
          <PageStatusMessage>{t('ui.loading')}...</PageStatusMessage> :
          <>
            <TwoColumnsPage>
              <Tabs
                value={currentTab}
                onChange={handleChange}
                minwidth='180px'
              >
                <Tab label={t('shops.subscription')} value='sub'/>
                {
                  // скрывать в режиме создания, если нет актуальной подписки, либо есть paymentError
                  (!shopId && !subscriptionId || paymentError) ? null : <Tab label={t('shops.information')} value='info'/>
                }
                {
                  shopId ? <Tab label={t('shops.map')} value='map' /> : null
                }
                {
                  shopId ? <Tab label={t('shops.configurator')} value='conf'/> : null
                }
              </Tabs>
              <Button
                mytype='secondary'
                width='180px'
                handleClick={handleCancel}
              >
                {t('ui.cancel')}
              </Button>
            </TwoColumnsPage>

            {renderTab()}
          </>
      }
    </>
  );
}

export default ShopEditPage;
