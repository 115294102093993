import React from "react";
import { Grid } from "@material-ui/core";
import Status from "../../../UI/Status";
import FormDetails from "../../../UI/Form/FormDetails";
import Button from "../../../UI/Button/Button";
import moment from "moment";
import { cleanStatus } from "../helpers/modifiers";
import PageLink from "../../../UI/other/PageLink";
import { useTranslation } from "react-i18next";
import getStatus from "../../../package/src/utils/getStatus";

const OrderHeader = ({order, orderId, isLoading, cancelOrder, handlePrint}) => {
  const orderDate = (moment && moment(order.createdAt).format("DD.MM.YYYY h:mm A")) || order.createdAt.toLocaleString();
  const { t } = useTranslation();

  const statusText = cleanStatus(order.status);
  const allStatuses = t('statuses', { returnObjects: true });
  const statusObj = getStatus(allStatuses, statusText);

  return (
    <>
      <Grid container spacing={2}>

        <Grid item xs={12} lg={7}>
          <div className='headingLeft'>
            <p className='textOrder'>{t('orders.order')} - {orderId}</p>
            {
              statusObj?.value && <Status
                content={statusObj.label}
                type={statusObj.value}
              />
            }
          </div>

          <FormDetails>
            <p className='text'>
              <span>{t('orders.placed')}: </span>{orderDate || 'N/A'}
            </p>
          </FormDetails>

          <PageLink handleClick={handlePrint}>{t('orders.print_invoice')}</PageLink>
        </Grid>

        {
          statusText !== 'canceled' &&
          <Grid item xs={12} lg={5} className='headingRight'>
            <Button
              width='160px'
              mytype='redSolid'
              handleClick={cancelOrder}
              disabled={isLoading}
            >
              {t('orders.cancel_order')}
            </Button>
          </Grid>
        }

      </Grid>
    </>

  );
}

export default OrderHeader;
