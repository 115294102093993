import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useTagsColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'tagName',
      label: t('tags.unit'),
      isBold: true,
      sortable: 'name',
    },
    {
      name: 'slug',
      label: t('tags.unit'),
    },
    {
      name: 'shop',
      label: t('tags.unit'),
      sortable: 'shopId',
    },
    {
      name: 'isVisible',
      label: t('tags.unit'),
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options',
    }
  ], [t]);
};
