import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import StreetMapSelector from "../../../package/src/StreetsSelector/StreetMapSelector";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import MapComponent from "../../street-map/components/Map";
import triggerScene from "../../street-map/utils/triggerScene"
import PropTypes from "prop-types";
import {
  availableStreetsQuery,
  getSubscriptionPackageMap,
  getSubscriptionPackagesForPlaces,
} from "../../street-map/graphql/queries";
import { addAllowedPlaces, removeAllowedPlaces } from "../../street-map/graphql/mutations";
import { useSnackbar } from "notistack";
import styled from 'styled-components';
import { getPackageStreetIds } from "../../subscription-packages/graphql/queries";
import { useTranslation } from "react-i18next";

const MapGrid = styled(Grid)`
  overflow: hidden;
`

/**
 *   The component is used in two modules:
 *   1. Shops -> Shop List -> Edit Shop
 *   2. Subscription -> Edit subscription package
 *   If isSubscriptionMode is false, then the script of the 1st module is activated.
 *   If isSubscriptionMode is true, then the script of the 2nd module is activated.
 * @param shopId
 * @param packageId
 * @param isSubscriptionMode
 * @param setCurrentTab
 * @returns {JSX.Element}
 * @constructor
 */

const MapTab = ({ shopId, packageId, isSubscriptionMode, setCurrentTab }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  
  const [streetsList, setStreetsList] = useState([]);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [currentHolderPlacement, setCurrentHolderPlacement] = useState(null);
  const [packages, setPackages] = useState([]);
  const [packageStreetsIds, setPackageStreetsIds] = useState([]); // menu 'Streets' from 'Information' tab
  /*
  Below: selected places on the map in 'subscription' mode.
  placesMap is used once when the map is loaded.
  placesMenu is used by the right menu. Updated with changes in map's places
   */
  const [placesMap, setPlacesMap] = useState([]);
  const [placesMenu, setPlacesMenu] = useState([]);
  const [subscriptionStreetsIds, setSubscriptionStreetsIds] = useState([]);

  const [ getPackageData, { data: packagesData } ] = useLazyQuery(getPackageStreetIds, {
    fetchPolicy: "no-cache",
    onError: (e) => {
      enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
      console.log(e.message);
    },
  });

  const [ getAvailableStreetsData, { data: rawStreetsList, refetch: refetchStreets }] = useLazyQuery(availableStreetsQuery, {
    fetchPolicy: "no-cache",
    onError: (e) => {
      enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
      console.log(e.message);
    },
  });

  const [ getSubscriptionPackageData, { data: subscriptionPackagesData } ] = useLazyQuery(getSubscriptionPackageMap, {
    fetchPolicy: "no-cache",
    onError: (e) => {
      enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
      console.log(e.message);
    },
  });

  const [ getPackages, { data: packagesRaw }] = useLazyQuery(getSubscriptionPackagesForPlaces, {
    fetchPolicy: "no-cache",
    variables: {
      streetId: selectedStreet,
    },
    onError: (e) => {
      enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
      console.log(e.message);
    },
  });

  const [ addPlace ] = useMutation(addAllowedPlaces, {
    ignoreResults: true,
    onError: (e) => {
      enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
      console.log(e.message);
    },
  });

  const [ removePlace ] = useMutation(removeAllowedPlaces, {
    ignoreResults: true,
    onError: (e) => {
      enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
      console.log(e.message);
    },
  });

  useEffect(() => {
    getAvailableStreetsData();

    if (!packageId) {
      return;
    }

    getPackageData({
      variables: {
        id: packageId,
      }
    });

    getSubscriptionPackageData({
      variables: {
        id: packageId,
      }
    });
  }, [packageId])

  useEffect(() => {
    if (rawStreetsList) {
      setStreetsList(rawStreetsList.availableStreets.data);
    }
  }, [rawStreetsList]);

  useEffect(() => {
    if (subscriptionPackagesData && packagesData && rawStreetsList) {
      const placesObj = subscriptionPackagesData.subscriptionPackage.placesOnStreets;
      setPlacesMap(placesObj);
      setPlacesMenu(placesObj);
      setSubscriptionStreetsIds(subscriptionPackagesData.subscriptionPackage.streetsIds);
      setPackageStreetsIds(packagesData.subscriptionPackage.streetsIds);
    }
  }, [subscriptionPackagesData, packagesData, packageId, rawStreetsList])

  useEffect(() => {
    if (selectedStreet) {
      triggerScene("eventSetSelectedStreet", selectedStreet);

      // if (isSubscriptionMode) {
        getPackages();
      // }
    }
  }, [selectedStreet, packageId]);

  useEffect(() => {
    if (packagesRaw) {
      setPackages(packagesRaw.getSubscriptionPackagesForPlaces);
    }
  }, [packagesRaw])

  const handleSelectHouse = useCallback((event) => {
    const { placesIds, streetId, selected } = event.detail ? event.detail : event;

    const options = {
      variables: {
        id: packageId,
        input: {
          placesIdsWithStreetId: {
            streetId,
            placesIds,
          }
        }
      }
    }

    if (selected) {
      addPlace(options).then(res => {
        setPlacesMenu(res.data.addAllowedPlaces.placesOnStreets);
        getPackages();
      });
    } else {
      removePlace(options).then(res => {
        setPlacesMenu(res.data.removeAllowedPlaces.placesOnStreets);
        getPackages();
      });
    }
  }, [packageId, addPlace, removePlace, getPackages])

  const handleSelectAllHouses = (event) => {
    triggerScene('eventSelectAllHouses', {
      isSelectAll: event.selected,
      streetId: event.streetId,
    });
    handleSelectHouse(event);
  }

  const handleSelectStreet = (streetId) => {
    setSelectedStreet(streetId);
  }

  const handleCurrentHolderPlacement = (streetId, placeId) => {
    setCurrentHolderPlacement({
      streetId: streetId,
      placeId: placeId
    });
  }

  return (
    <Grid container spacing={7}>
      <MapGrid item xs={12} lg={8}>
        <MapComponent
          shopId={shopId}
          streetsList={streetsList}
          packageStreetsIds={packageStreetsIds}
          needUpdatePlaces={refetchStreets}
          setSelectedStreetId={handleSelectStreet}
          handleCurrentHolderPlacement={handleCurrentHolderPlacement}
          isSubscriptionMode={isSubscriptionMode}
          handleSelectHouse={handleSelectHouse}
          placesOnStreets={placesMap}
          subscriptionAvailableStreets={subscriptionStreetsIds}
          packages={packages}
          setCurrentTab={setCurrentTab}
        />
      </MapGrid>
      <Grid item xs={12} lg={4}>
        <StreetMapSelector
          isDetailed={Boolean(shopId)}
          streetsList={streetsList}
          selectedStreet={selectedStreet}
          setSelectedStreet={setSelectedStreet}
          handleSelectAllHouses={handleSelectAllHouses}
          currentHolderPlacement={currentHolderPlacement}
          placesOnStreets={placesMenu}
          subscriptionAvailableStreets={subscriptionStreetsIds}
        />
      </Grid>
    </Grid>
  );
};

MapTab.propTypes = {
  shopId: PropTypes.string,
  packageId: PropTypes.string,
  isSubscriptionMode: PropTypes.bool,
};

export default MapTab;
