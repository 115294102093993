import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  ButtonBase,
} from "@material-ui/core";
import Button from "../../../UI/Button/Button";
import InputLabel from "../../../UI/Form/InputLabel";
import { useApolloClient } from "@apollo/react-hooks";
import { takePlaceOnStreet, freePlaceOnStreet } from "../graphql/mutations";
import { shopQuery } from "../../shops/graphql/queries";
import triggerScene from "../utils/triggerScene";
import { ReactComponent as ArrowsSvg } from "../../../icons/arrows.svg";
import { ReactComponent as PlusSvg } from "../../../icons/plusButton.svg";
import { ReactComponent as MinusSvg } from "../../../icons/minusButton.svg";
import MapScene from "./Scene";
import CustomSvgButton from "./SvgButton";
import convertStreets from "../utils/convertStreets";
import CustomTooltip from "./CustomTooltip";
import PageStatusMessage from "../../../UI/other/PageStatusMessage";
import { useSnackbar } from "notistack";
import { getShopCategoriesQuery } from "../../shop-categories/graphql/queries";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  sceneContainer: {
    // width: "90%",
    height: "70vh",
    maxHeight: "80vh",
    display: "flex",
    justifyContent: "flex-start",
  },
  toolTipButtonContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "10px"
  },
  handleButton: {
    width: "44px",
    height: "44px",
    background: "#F2F2F2",
    borderRadius: "20px",
  },
}));


const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: "218px",
        height: "inherit",
        maxHeight: "200px",
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        pointerEvents: "auto",
        padding: '10px',
        boxShadow: '0px 4px 10px rgba(0,0,0,0.05)',
      },
      arrow: {
        color: "#FFFFFF",
      },
    },
    button: {
      textTransform: 'lowercase'
    }
  },
});

function MapComponent({
  shopId,
  streetsList,
  needUpdatePlaces,
  setSelectedStreetId,
  handleCurrentHolderPlacement,
  isSubscriptionMode,
  handleSelectHouse,
  placesOnStreets,
  subscriptionAvailableStreets,
  packages,
  packageStreetsIds,
  setCurrentTab
}) {
  if (isSubscriptionMode && !packageStreetsIds) {
    // if user in 'subscription' module, we need to know about the selected package streets in 'Information' tab
    return <PageStatusMessage>Loading...</PageStatusMessage>;
  }

  const { t } = useTranslation();
  const classes = useStyles();
  let canvasRef = React.createRef();
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();

  const [viewGl, setViewGl] = useState(null);
  const [chooseToolTipIsOpen, setChooseToolTipIsOpen] = useState(false);
  const [occupiedToolTipIsOpen, setOccupiedToolTipIsOpen] = useState(false);
  const [upgradeToolTipIsOpen, setUpgradeToolTipIsOpen] = useState(false);
  const [placeToolTipIsOpen, setPlaceToolTipIsOpen] = useState(false);
  const [currentStreetId, setCurrentStreetId] = useState(null);
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);
  const [holderCurrentPlacement, setHolderCurrentPlacement] = useState(null);
  const [occupiedText, setOccupiedText] = useState(t('street.location_occupied_message'));
  const [upgradePackageText] = useState(t('street.upgrade_subscription_message'));
  const [occupiedStoreName, setOccupiedStoreName] = useState("");
  const [position, setPosition] = useState({ x: undefined, y: undefined });
  const [positionForPlaceToolTip, setPositionForPlaceToolTip] = useState({ x: undefined, y: undefined });
  const [streetsSize, setStreetsSize] = useState(-1);
  const [streetCategories, setStreetCategories] = useState(null);
  const [streetCategoriesLoaded, setStreetCategoriesLoaded] = useState(false);
  const [streetCategoriesLoading, setStreetCategoriesLoading] = useState(false);

  const loadStreetCategories = () => {
    if (!streetCategoriesLoading) setStreetCategoriesLoading(true);
    setStreetCategoriesLoaded(false)
    if (!streetsList.length){
      return ;
    }
    let totalCategories = new Set();
    streetsList.forEach((street) => {
      street.categories.forEach((category)=>{totalCategories.add(category)});
    });
    return apolloClient.query({
      query: getShopCategoriesQuery,
      variables: {
        shopCategoryIds: Array.from(totalCategories)
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        setStreetCategories(res.data.getShopCategories.nodes);
        setStreetCategoriesLoaded(true);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => {
        setStreetCategoriesLoading(false);
      })
  };

  const freePlace = (data) => {
    return apolloClient.mutate({
      mutation: freePlaceOnStreet,
      variables: {
        input: data,
      },
    });
  };

  const takePlace = (data) => {
    data["holderId"] = shopId;
    return apolloClient.mutate({
      mutation: takePlaceOnStreet,
      variables: {
        input: data,
      },
    });
  };

  const getShopName = (idOfShop) => {
    for (const street of streetsList) {
        for (const place of street.places) {
          if (place.holder !== null){
            if (place.holder.holderId === idOfShop) {
              return place.holder.holderName;
            }
          }
      }
    }
    return t('street.another_shop');
  };

  const handleToggleView = () => {
    const placesNumbers = packages.map(item => item.subscriptionPackages.length);
    triggerScene("handleToggleView", { placesNumbers });
  };

  const handleChooseButtonClick = () => {
    let data = {
      streetId: currentStreetId,
      placeId: selectedPlaceId.toString(),
      holderType: "shop",
      holderId: shopId,
    };
    if (holderCurrentPlacement === null) {
      takePlace(data).then(({ resultTakePlaceData, errors }) => {
        handleCurrentHolderPlacement(data.streetId, data.placeId);
        needUpdatePlaces();
        triggerScene("handleUpdateView", data);
        if (resultTakePlaceData || errors) {
          console.log(resultTakePlaceData, errors);
        }
      }).catch(({graphQLErrors, networkError})=>{
        if (graphQLErrors){
          enqueueSnackbar(graphQLErrors[0].message, {variant: 'error'});
        }
        if (networkError) {
          enqueueSnackbar(networkError, {variant: 'error'});
        }
      });
    } else {
      freePlace(holderCurrentPlacement).then(
        ({ resultFreeData, errors }) => {
          if (resultFreeData || errors) {
            console.log(resultFreeData, errors);
          }
        }).catch(({graphQLErrors, networkError}) => {
          if (graphQLErrors){
            enqueueSnackbar(graphQLErrors[0].message, {variant: 'error'});
          }
          if (networkError) {
            enqueueSnackbar(networkError, {variant: 'error'});
          }
        }).finally(()=>{
          takePlace(data).then(({ resultTakePlaceData, errors }) => {
            needUpdatePlaces();
            triggerScene("handleUpdateView", data);
            handleCurrentHolderPlacement(data.streetId, data.placeId);
            if (resultTakePlaceData || errors) {
              console.log(resultTakePlaceData, errors);
            }
          }).catch(({graphQLErrors, networkError}) => {
            if (graphQLErrors){
              enqueueSnackbar(graphQLErrors[0].message, {variant: 'error'});
            }
            if (networkError) {
              enqueueSnackbar(networkError, {variant: 'error'});
            }
        });
      });
    }
  };

  const handleUpgradeButtonClick = () => {
    setCurrentTab('sub');
  };

  const handleUpgradeToolTipIsOpen = (event) => {
    if (isSubscriptionMode) {
      return;
    }
    setCurrentStreetId(event.detail.streetId);
    setSelectedPlaceId(event.detail.placeId);
    setUpgradeToolTipIsOpen(true);
  }

  const handleChooseToolTipIsOpen = (event) => {
    setCurrentStreetId(event.detail.streetId);
    setSelectedPlaceId(event.detail.placeId);

    if (isSubscriptionMode) {
      return;
    }

    setHolderCurrentPlacement(event.detail.currentPlacement);
    setChooseToolTipIsOpen(true);
  };

  const handleOccupiedToolTipIsOpen = (event) => {
    if (isSubscriptionMode) {
      setCurrentStreetId(event.detail.streetId);
      setSelectedPlaceId(event.detail.placeId);
      return;
    }

    setOccupiedText(event.detail.text);
    setOccupiedStoreName(getShopName(event.detail.holderId));
    setOccupiedToolTipIsOpen(true);
  };

  const handlePlaceTooltip = (event) => {
    setSelectedPlaceId(event.detail.placeId);
    if (event.detail["mousePosition"] === undefined) {
      setPlaceToolTipIsOpen(false);
      return;
    }
    const isOpen = event.detail.isOpen;
    setPositionForPlaceToolTip({x: event.detail["mousePosition"].x, y: event.detail["mousePosition"].y})
    setPlaceToolTipIsOpen(isOpen);
  }

  const handleZoomIn = (event) => {
    triggerScene("handleZoomIn", event);
  };

  const handleZoomOut = (event) => {
    triggerScene("handleZoomOut", event);
  };

  const handleSelectedStreetChanged = (event) => {
    setSelectedStreetId(event.detail.selectedStreetId);
  };

  const handleSelectedStreetDoubleClicked = () => {
    handleToggleView();
  }

  useEffect(() => {
    if (canvasRef.current && (streetsList.length !== streetsSize) && (streetsList.length)) {
      setStreetsSize(streetsList.length)
      const viewGl = new MapScene(canvasRef.current, isSubscriptionMode);
      setViewGl(viewGl);
    }
  }, [canvasRef.current, streetsList])

  useEffect(() => {
    if (!viewGl || !streetCategoriesLoaded) {
      loadStreetCategories();
      return;
    }
    if (!streetCategories) {
      return;
    }

    const locales = t('street', { returnObjects: true });
    const convertedData = convertStreets(streetsList, placesOnStreets, streetCategories);
    viewGl.loadCity(convertedData, shopId, placesOnStreets, subscriptionAvailableStreets, locales);
    const placement = viewGl.city.getCurrentHolderHousePlacement();
    const placesNumbers = packages.map(item => item.subscriptionPackages.length);

    if (placement) {
      handleCurrentHolderPlacement(placement.streetId, [placement.placeId]);
      viewGl.selectStreet(placement.streetId, placesNumbers);
      viewGl.goInside(placesNumbers);
    }

    const handleUpdateView = (event) => {
      let placement = {
        streetId: event.detail.streetId,
        placeId: event.detail.placeId,
      };
      viewGl.updateHousesView(placement);
    };
    const handleToggleView = (event) => {
      viewGl.toggleView(event.detail.placesNumbers);
    };
    const handleMouseDown = () => {
      setUpgradeToolTipIsOpen(false);
      setOccupiedToolTipIsOpen(false);
      setChooseToolTipIsOpen(false);
    }
    const handleMouseClick = (event) => {
      setChooseToolTipIsOpen(false);
      setPosition({ x: event.pageX, y: event.pageY });
      viewGl.onClick();
    };
    const handleMouseMove = (event) => {
      viewGl.onPointerMove(event);
    };
    const handleResize = () => {
      viewGl.onWindowResize(window.innerWidth, window.innerHeight);
    };
    const handleZoomIn = () => {
      viewGl.zoomInFunction();
    };
    const handleZoomOut = () => {
      viewGl.zoomOutFunction();
    };
    const handleSetSelectedStreet = (event) => {
      setCurrentStreetId(event.detail);
      viewGl.selectStreet(event.detail, placesNumbers);
    };
    const handleSelectAllHouses = (event) => {
      viewGl.selectAllHouses(event.detail);
    }

    document.addEventListener("selectedStreetIdChanged", handleSelectedStreetChanged);
    document.addEventListener("selectedStreetDoubleClicked", handleSelectedStreetDoubleClicked);
    document.addEventListener("eventSetSelectedStreet", handleSetSelectedStreet);
    document.addEventListener("eventSetSelectedHouse", handleSelectHouse);
    document.addEventListener("eventSelectAllHouses", handleSelectAllHouses);
    document.addEventListener("handleZoomIn", handleZoomIn);
    document.addEventListener("handleZoomOut", handleZoomOut);
    document.addEventListener("handleToggleView", handleToggleView);
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseClick);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", handleResize);
    document.addEventListener("handleChooseToolTipIsOpen", handleChooseToolTipIsOpen);
    document.addEventListener("handleOccupiedToolTipIsOpen", handleOccupiedToolTipIsOpen);
    document.addEventListener("handleUpgradeToolTipIsOpen", handleUpgradeToolTipIsOpen);
    document.addEventListener("handlePlaceToolTip", handlePlaceTooltip);
    document.addEventListener("handleUpdateView", handleUpdateView);
    return () => {
      document.removeEventListener("selectedStreetIdChanged", handleSelectedStreetChanged);
      document.removeEventListener("selectedStreetDoubleClicked", handleSelectedStreetDoubleClicked);
      document.removeEventListener("eventSetSelectedStreet", handleSetSelectedStreet);
      document.removeEventListener("eventSetSelectedHouse", handleSelectHouse);
      document.removeEventListener("eventSelectAllHouses", handleSelectAllHouses);
      document.removeEventListener("handleZoomIn", handleZoomIn);
      document.removeEventListener("handleZoomOut", handleZoomOut);
      window.removeEventListener("mouseup", handleMouseClick);
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("handleToggleView", handleToggleView);
      document.removeEventListener("handleChooseToolTipIsOpen", handleChooseToolTipIsOpen);
      document.removeEventListener("handleOccupiedToolTipIsOpen", handleOccupiedToolTipIsOpen);
      document.removeEventListener("handleUpgradeToolTipIsOpen", handleUpgradeToolTipIsOpen);
      document.removeEventListener("handlePlaceToolTip", handlePlaceTooltip);
      document.removeEventListener("handleUpdateView", handleUpdateView);
      viewGl.clearMemory();
    };
  }, [t, viewGl, placesOnStreets.length, placesOnStreets, streetCategoriesLoaded, streetCategories])

  useEffect(() => {
    if (packages.length === 0) {
      return;
    }
    const placesNumbers = packages.map(item => item.subscriptionPackages.length);
    viewGl.updatePlacesNumbers(placesNumbers);
  }, [packages])

  const renderPackageTooltipData = useMemo(() => {
    return packages
      .find(pack => pack.place.id === String(selectedPlaceId))?.subscriptionPackages
      .map(item => <p key={item._id}>
        {item.title}
      </p>);
  }, [selectedPlaceId, packages])

  return (
    <div>
      <div
        id="mapContainerId"
        className={classes.sceneContainer}
        onMouseMove={e => {
          if (!chooseToolTipIsOpen && !occupiedToolTipIsOpen && !placeToolTipIsOpen && !upgradeToolTipIsOpen) {
            setPosition({ x: e.pageX, y: e.pageY });
          } else {
            if (Math.abs(e.pageY - position.y) > 100) {
              setChooseToolTipIsOpen(false);
              setOccupiedToolTipIsOpen(false);
              setUpgradeToolTipIsOpen(false);
            }
            if (Math.abs(e.pageX - position.x) > 100) {
              setChooseToolTipIsOpen(false);
              setOccupiedToolTipIsOpen(false);
              setUpgradeToolTipIsOpen(false);
            }
          }
        }}
      >
        <canvas ref={canvasRef}>
        </canvas>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "150px" }}>
        <ButtonBase className={classes.handleButton} focusRipple onClick={handleToggleView}>
          <CustomSvgButton svg={ArrowsSvg} />
        </ButtonBase>
        <ButtonBase className={classes.handleButton} focusRipple onClick={handleZoomIn}>
          <CustomSvgButton svg={PlusSvg} />
        </ButtonBase>
        <ButtonBase className={classes.handleButton} focusRipple onClick={handleZoomOut}>
          <CustomSvgButton style={{ marginTop: "15px" }} svg={MinusSvg} />
        </ButtonBase>
      </div>

      <MuiThemeProvider theme={theme}>

        <CustomTooltip
          isOpen={chooseToolTipIsOpen}
          position={position}
          title={
            <div>
              <InputLabel>{t('street.free_location')}</InputLabel>
              <div className={classes.toolTipButtonContainer}>
                <Button style={{ width: "180px", textTransform: 'none'}}
                        onClick={handleChooseButtonClick}
                >
                  {t('street.choose')}
                </Button>
              </div>
            </div>
          }
        />

        <CustomTooltip
          isOpen={occupiedToolTipIsOpen}
          position={position}
          title={
            <div>
              <InputLabel>
                {occupiedStoreName}
              </InputLabel>
              <div style={{ fontSize: "15px" }}>
                {occupiedText}
              </div>
            </div>
          }
        />

        <CustomTooltip
          isOpen={upgradeToolTipIsOpen}
          position={position}
          title={
            <div style={{margin: "5px"}}>
              <InputLabel>{t('street.free_location')}</InputLabel>
              <div style={{ fontSize: "14px" }}>
                {upgradePackageText}
              </div>
              <div className={classes.toolTipButtonContainer}>
                <Button
                        style={{ width: "180px", textTransform: 'none'}}
                        onClick={handleUpgradeButtonClick}
                >
                  {t('street.upgrade')}
                </Button>
              </div>
            </div>
          }
        />

        {
          renderPackageTooltipData?.length ?
            <CustomTooltip
              isOpen={placeToolTipIsOpen}
              position={positionForPlaceToolTip}
              title={
                <div style={{ fontSize: '15px', margin: 0 }}>
                  {
                    renderPackageTooltipData
                  }
                </div>
              }
            /> : null
        }

      </MuiThemeProvider>
    </div>
  );
}

export default MapComponent;
