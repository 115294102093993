import React, { useCallback, useEffect, useState } from "react";
import styled from 'styled-components';
import FormHeader from "../../../../UI/Form/FormHeader";
import { Form } from '../../../../UI/Form/MyForm';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {InviteSchema} from '../schemas';
import InputLabel from "../../../../UI/Form/InputLabel";
import FormInput from "../../../../UI/Form/FormInput";
import { Grid, Tab } from "@material-ui/core";
import Tabs from "../../../../UI/Tabs/Tabs";
import SelectAllAutocomplete from "../../../../package/src/SelectAllAutocomplete";
import { constants } from "../../../../constants";
import Button from "../../../../UI/Button/Button";
import SaveButtons from "../../../../UI/Button/SaveButtons";
import getGroupSlugs from '../graphql/queries/accountTypes';
import inviteShopMember from "../graphql/mutations/inviteShopMember";
import {getPrimaryShopId} from '../graphql/queries/getPrimaryShopId';
import { useTranslation } from "react-i18next";
import FormNote from "../../../../UI/Form/FormNote";
import useAccountTypes from "../hooks/useAccountTypes";

const StyledTabPages = styled.div`
  height: 180px;
  position: relative;
  width: 100%;
`

const StyledTabContent = styled.div`
  width: 100%;
  position: absolute;
  visibility: ${({tab, tabValue}) => tabValue === tab ? 'visible' : 'hidden'};
`

const InviteShopMemberDialog = ({declineAction, apolloClient, enqueueSnackbar, allShops, godmode}) => {
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(godmode ? 'obuy' : 'merchant');
  const [groupSlugsList, setGroupSlugsList] = useState([]);
  const [merchantGroupsList, setMerchantGroupsList] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [loading, setLoading] = useState(true);

  const { getAccountTypeLabel } = useAccountTypes();

  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    resolver: yupResolver(InviteSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      groupSlugs: [],
      shopIds: [],
      merchantGroups: [],
    },
  });

  useEffect(() => {
    // get shopList
    const shopsForSelect = allShops?.map(shop => ({
      value: shop._id,
      label: shop.name,
      companyId: shop.company._id,
      groupId: shop.company.name,
    })) || [];

    setShopList(shopsForSelect);

    // get groupSlugsList and merchantGroupsList
    apolloClient.query({
      query: getGroupSlugs,
      fetchPolicy: "network-only",
    })
      .then(res => {
        const data = res.data.getAccountTypes;
        const groupSlugs = data.filter(accountType => accountType.slug.includes("godmode"))
          .map(accountType => ({
            value: accountType.slug,
            label: getAccountTypeLabel(accountType.slug) || `${constants.APP_NAME} ${accountType.name}`,
          }));

        const merchantGroups = data.filter(accountType => !accountType.slug.includes("godmode"))
            .map(accountType => ({
              value: accountType.slug,
              label: getAccountTypeLabel(accountType.slug) || accountType.name,
            }));

        setGroupSlugsList(groupSlugs);
        setMerchantGroupsList(merchantGroups);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setLoading(false))
  }, [t, getAccountTypeLabel])

  const handleChange = (_, newValue) => {
    setTabValue(newValue);
  };

  const mutationInvite = useCallback((formData, primaryShopId) => {
    return apolloClient.mutate({
      mutation: inviteShopMember,
      variables: {
        input: {
          email: formData.email,
          groupSlugs: formData.groupSlugs.length > 0 ? formData.groupSlugs.map(i => i.value) : formData.merchantGroups.map(i => i.value),
          shopIds: formData.groupSlugs.length > 0 ? [primaryShopId] : formData.shopIds,
          firstName: formData.firstName,
          lastName: formData.lastName,
          shouldGetAdminUIAccess: true,
          password: formData.password,
        }
      },
    });
  }, [])

  const getPrimaryShop = () => {
    return apolloClient.query({
      query: getPrimaryShopId,
      variables: {},
      fetchPolicy: "network-only",
    });
  }

  const onSubmit = useCallback((formData) => {
    setLoading(true);
    getPrimaryShop()
      .then((res) => {
        return mutationInvite(formData, res.data.primaryShopId);
      })
      .then(() => {
        setLoading(false);
        enqueueSnackbar(t('account.invited_member_success'), {
          variant: "success",
        });
        declineAction();
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar(e.message.replace("GraphQL error:", ""), { variant: "error" });
        console.log(e.message);
      })
  }, [t])

  const handleShopsChange = (e, func) => {
    func(e.map(i => i.value));
  }

  const validateOnChange = (selected) => {
    if (
      !selected?.length ||
      (selected?.length &&
        selected?.every(shop => shop.companyId === selected[0].companyId))
    ) {
      return true;
    }
    enqueueSnackbar(t('account.shops_select_error'), { variant: "error" });
    return false;
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeader>{t('account.invite_staff_member')}</FormHeader>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <InputLabel error={errors.firstName} disabled={loading}>{t('account.first_name')}</InputLabel>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => <FormInput
              placeholder={t('account.write_first_name')}
              value={field.value}
              onChange={field.onChange}
              error={errors.firstName}
              disabled={loading}
            />}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputLabel error={errors.lastName} disabled={loading}>{t('account.last_name')}</InputLabel>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => <FormInput
              placeholder={t('account.write_surname')}
              value={field.value}
              onChange={field.onChange}
              error={errors.lastName}
              disabled={loading}
            />}
          />
        </Grid>
      </Grid>

      <InputLabel error={errors.email} disabled={loading}>{t('account.email')}</InputLabel>
      <Controller
        name="email"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('account.write_email')}
          value={field.value}
          onChange={field.onChange}
          error={errors.email}
          disabled={loading}
        />}
      />

      <InputLabel error={errors.password} disabled={loading}>{t('account.password')}</InputLabel>
      <Controller
        name="password"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('account.write_password')}
          value={field.value}
          onChange={field.onChange}
          error={errors.password}
          type='password'
          disabled={loading}
        />}
      />

      <Tabs
        value={tabValue}
        onChange={handleChange}
        issmall
      >
        {
          godmode &&
          <Tab label="Wondersouq" value='obuy'/>
        }
        <Tab label={t('account.merchant')} value='merchant'/>
      </Tabs>

      <StyledTabPages>
        <StyledTabContent tab='obuy' tabValue={tabValue}>
          <InputLabel disabled={loading}>{t('account.wondersouq_groups')}</InputLabel>
          <Controller
            name="groupSlugs"
            control={control}
            render={({ field }) => <SelectAllAutocomplete
              placeholder={t('account.select_groups')}
              selectAllLabel={t('account.all_groups')}
              value={field.value}
              onChange={field.onChange}
              items={groupSlugsList}
              isMultiSelect
              disabled={loading}
            />}
          />
          <FormNote>{t('account.members_visibility_note')}</FormNote>
        </StyledTabContent>

        <StyledTabContent tab='merchant' tabValue={tabValue}>
          <InputLabel disabled={loading}>{t('account.groups')}</InputLabel>
          <Controller
            name="merchantGroups"
            control={control}
            render={({ field }) => <SelectAllAutocomplete
              placeholder={t('account.select_groups')}
              selectAllLabel={t('account.all_groups')}
              value={field.value}
              onChange={field.onChange}
              items={merchantGroupsList}
              isMultiSelect
              disabled={loading}
            />}
          />
          <InputLabel error={errors.shopIds} disabled={loading}>{t('account.shops')}</InputLabel>
          <Controller
            name="shopIds"
            control={control}
            render={({ field }) => <SelectAllAutocomplete
              placeholder={t('account.select_shops')}
              selectAllLabel={t('account.all_shops')}
              onChange={(e) => handleShopsChange(e, field.onChange)}
              items={shopList.map(item => ({
                ...item,
                selected: field.value.includes(item.value),
              }))}
              isMultiSelect
              disabled={loading}
              error={errors.shopIds}
              validateOnChange={validateOnChange}
            />}
          />
        </StyledTabContent>
      </StyledTabPages>

      <SaveButtons margin='50px 0 0'>
        <Button
          disabled={!isDirty || loading}
          type='submit'
          width='180px'
          handleClick={handleSubmit}
        >
          {t('account.invite')}
        </Button>
        <Button
          mytype='third'
          width='180px'
          handleClick={declineAction}
          disabled={loading}
        >
          {t('ui.cancel')}
        </Button>
      </SaveButtons>
    </Form>
  );
}

export default InviteShopMemberDialog;
