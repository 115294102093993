import React, { useEffect, useState } from "react";
import FormHeader from "../../../UI/Form/FormHeader";
import { Form } from "../../../UI/Form/MyForm";
import InputLabel from "../../../UI/Form/InputLabel";
import SaveButtons from "../../../UI/Button/SaveButtons";
import FormInput from "../../../UI/Form/FormInput";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import CheckboxContainer from "../../../UI/Checkbox/CheckboxContainer";
import { getTag, tagProductsQueryString } from "../lib/queries";
import { addTagMutation, updateTagMutation } from "../lib/mutations";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";
import Button from "../../../UI/Button/Button";
import { useSnackbar } from "notistack";
import TagDropzone from '../components/TagDropzone';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {TagSchema} from '../schemas';
import config from "../../../config";
import { useApolloClient } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

const filesBaseUrl = config.PUBLIC_FILES_BASE_URL + '/';

const TagFormPageWithData = ({shopId, viewerId, closeDialog, page, tagId, setTagId, refetch}) => {
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();

  const [mediaURL, setMediaURL] = useState('');
  const [loading, setLoading] = useState(true);
  const [productsList, setProductsList] = useState([]);

  const { control, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
    resolver: yupResolver(TagSchema),
    defaultValues: {
      displayTitle: '',
      slug: '',
      isStorefront: false,
      keywords: '',
      products: [],
      description: '',
      graphTitle: '',
      graphDescription: '',
      graphURL: '',
      graphImageURL: '',
      graphLocate: '',
      graphFacebook: '',
    },
  });

  const getProducts = () => {
    return apolloClient.query({
      query: tagProductsQueryString,
      variables: {
        shopId,
        tagId
      }
    })
  }

  const getTagParams = () => {
    if (page !== 'editTag') {
      return Promise.resolve();
    }
    return apolloClient.query({
      query: getTag,
      variables: {
        shopId,
        slugOrId: tagId,
      }
    })
  }

  useEffect(() => {
    if (!shopId || !tagId) {
      setLoading(false);
      return;
    }

    Promise.all([
      getProducts(),
      getTagParams(),
    ])
      .then(res => {
        const products = res[0].data.productsByTagId.nodes.map(item => ({
          value: item._id,
          label: item.title,
        }));
        setProductsList(products);

        if (!res[1]) {
          return;
        }

        const tag = res[1].data.tag;
        const meta = tag.metafields;
        reset({
          displayTitle: tag.displayTitle || '',
          slug: tag.slug || '',
          isStorefront: tag.isVisible,
          keywords: meta?.find(i => i.key === 'keywords')?.value || '',
          description: meta?.find(i => i.key === 'description')?.value || '',
          graphTitle: meta?.find(i => i.key === 'og:title')?.value || '',
          graphDescription: meta?.find(i => i.key === 'og:description')?.value || '',
          graphURL: meta?.find(i => i.key === 'og:url')?.value || '',
          graphImageURL: meta?.find(i => i.key === 'og:image')?.value || '',
          graphLocate: meta?.find(i => i.key === 'og:locale')?.value || '',
          graphFacebook: meta?.find(i => i.key === 'fb:app_id')?.value || '',
        });

        let url = tag.heroMediaUrl || '';
        if (tag.heroMediaUrl === filesBaseUrl) {
          url = mediaURL.replace(filesBaseUrl, '');
        }
        setMediaURL(url);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setLoading(false))
  }, [t])

  const handleTagMutations = (formData) => {
    setLoading(true);
    const name = formData.displayTitle.replace(' ', '-').toLowerCase();
    const myTagId = {};
    if (page === 'editTag') {
      myTagId.id = tagId;
    }

    return apolloClient.mutate({
      mutation: page === 'createTag' ? addTagMutation : updateTagMutation,
      variables: {
        input: {
          ...myTagId,
          displayTitle: formData.displayTitle,
          // todo position: formData.products
          name,
          slug: formData.slug,
          shopId,
          isVisible: formData.isStorefront,
          heroMediaUrl: mediaURL.replace(filesBaseUrl, ''),
          metafields: [
            { key: "keywords", value: formData.keywords, namespace: "metatag" },
            { key: "description", value: formData.description, namespace: "metatag" },
            { key: "og:title", value: formData.graphTitle, namespace: "metatag" },
            { key: "og:description", value: formData.graphDescription, namespace: "metatag" },
            { key: "og:url", value: formData.graphURL, namespace: "metatag" },
            { key: "og:image", value: formData.graphImageURL, namespace: "metatag" },
            { key: "og:locale", value: formData.graphLocate, namespace: "metatag" },
            { key: "fb:app_id", value: formData.graphFacebook, namespace: "metatag" },
          ],
        },
      },
    })
  }

  const onSubmit = (newData) => {
    handleTagMutations(newData)
      .then(res => {
        setLoading(false);
        if (page === 'createTag') {
          const newId = res.data.addTag.tag._id;
          setTagId(newId);
        }
        closeDialog();
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        setLoading(false);
        console.log(e.message);
      })
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeader>{page === 'createTag' ? t('tags.create_tag') : t('tags.edit_tag')}</FormHeader>
      <InputLabel
        error={errors.displayTitle}
        helpText={t('tags.tag_display_hint')}
        disabled={loading}
      >
        {t('tags.tag_name')}
      </InputLabel>
      <Controller
        name="displayTitle"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('tags.write_tag_name')}
          value={field.value}
          onChange={field.onChange}
          error={errors.displayTitle}
          disabled={loading}
        />}
      />
      <InputLabel disabled={loading}>{t('tags.slug')}</InputLabel>
      <Controller
        name="slug"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('tags.write_slug')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />
      <CheckboxContainer>
        <Controller
          name="isStorefront"
          control={control}
          render={({ field }) => <Checkbox
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
          />}
        />
        {t('tags.tag_enabled_in_storefront')}
      </CheckboxContainer>
      <InputLabel
        helpText={t('tags.aspect_ratio_hint')}
        disabled={loading}
      >
        {t('tags.tag_listing_page_image_hero')}
      </InputLabel>

      <TagDropzone
        mediaURL={mediaURL}
        setMediaURL={setMediaURL}
        viewerId={viewerId}
        shopId={shopId}
        tagId={tagId}
      />

      <InputLabel disabled={loading}>{t('tags.hero_media_url')}</InputLabel>
      <Controller
        name="mediaURL"
        control={control}
        render={({ field }) => <FormInput
          placeholder='URL'
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />
      <InputLabel disabled={loading}>{t('tags.keywords')}</InputLabel>
      <Controller
        name="keywords"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('tags.write_keywords_here')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />
      <InputLabel disabled={loading}>{t('tags.products')}</InputLabel>
      <Controller
        name="products"
        control={control}
        render={({ field }) => <SelectAllAutocomplete
          items={productsList}
          placeholder={t('tags.choose_products')}
          selectAllLabel={t('tags.all_products')}
          isMultiSelect
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />
      <InputLabel disabled={loading}>{t('tags.description')}</InputLabel>
      <Controller
        name="description"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('tags.write_description_here')}
          value={field.value}
          onChange={field.onChange}
          multiline
          rows={4}
          disabled={loading}
        />}
      />

      <FormHeader disabled={loading}>OpenGraph</FormHeader>
      <InputLabel optionalText='[og:title]'>{t('tags.title')}</InputLabel>
      <Controller
        name="graphTitle"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('tags.write_title_here')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />
      <InputLabel optionalText='[og:description]' disabled={loading}>{t('tags.description')}</InputLabel>
      <Controller
        name="graphDescription"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('tags.write_description_here')}
          value={field.value}
          onChange={field.onChange}
          multiline
          rows={4}
          disabled={loading}
        />}
      />
      <InputLabel optionalText='[og:url]' disabled={loading}>URL</InputLabel>
      <Controller
        name="graphURL"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('tags.write_title_here')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />
      <InputLabel optionalText='[og:image]' disabled={loading}>{t('tags.image_url')}</InputLabel>
      <Controller
        name="graphImageURL"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('tags.write_title_here')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />
      <InputLabel optionalText='[og:locate]' disabled={loading}>{t('tags.locate')}</InputLabel>
      <Controller
        name="graphLocate"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('tags.write_title_here')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />
      <InputLabel optionalText={t('ui.optional')} disabled={loading}>
        {t('tags.facebook_add_id')}
      </InputLabel>
      <Controller
        name="graphFacebook"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('tags.write_title_here')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />

      <SaveButtons>
        <Button
          disabled={!isDirty || loading}
          type='submit'
          width='180px'
          handleClick={handleSubmit}
        >
          {page === 'createTag' ? t('ui.create') : t('ui.save_changes')}
        </Button>
        <Button
          disabled={loading}
          mytype='third'
          width='180px'
          handleClick={closeDialog}
        >
          {t('ui.cancel')}
        </Button>
      </SaveButtons>
    </Form>
  );
}

export default TagFormPageWithData;
